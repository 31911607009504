import React,{useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap"
import { graphql} from "gatsby"
import { useLocation } from "@reach/router"

import Layout from "gatsby-theme-starberry-lomondgroup/src/components/Layout"
import Customer from "gatsby-theme-starberry-lomondgroup/src/components/Customer/Customer";
import Journey from "gatsby-theme-starberry-lomondgroup/src/components/Journey/Journey";
import LocalExperience from "gatsby-theme-starberry-lomondgroup/src/components/LocalExperience/LocalExperienceOffice"
import Features from "gatsby-theme-starberry-lomondgroup/src/components/Features/Features";
import PropertySearch from "gatsby-theme-starberry-lomondgroup/src/components/PropertySearch/PropertySearch"
import ServiceContentCard from "gatsby-theme-starberry-lomondgroup/src/components/ServiceContentCard/ServiceContentCard"
import TrustpilotReviews from "gatsby-theme-starberry-lomondgroup/src/components/TrustpilotReviews/TrustpilotReviews";
import Info from "gatsby-theme-starberry-lomondgroup/src/components/Info/Info"
import Banner from "gatsby-theme-starberry-lomondgroup/src/components/Banner/Banner"
import International from "../components/International/International"

import {slider_count} from "../common/constant"

// markup
const InternationalDetails = (props) => {
    const location = useLocation();

    var international_data = props.data.glstrapi?.internationalLocation;
    var GQLModules = international_data.add_components;
    var GQLPage = international_data


    let meta_title = international_data?.seo_meta?.title ? international_data?.seo_meta?.title : international_data?.title ? international_data?.title : "";

    let meta_desc = international_data?.seo_meta?.description ? international_data?.seo_meta?.description : `Our ${international_data?.title} offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance.`;

    let meta_data = meta_title ? {title: `${meta_title}`, description: meta_desc} : "";


    return (
        // loading ? 
        //     <PreLoader />
        // :
        <div className={`international_details`}>
            
            <Layout seo_meta={GQLPage?.seo} menu_slug={"international-details"} GQLPage={{layout:"static_template"}} currency={international_data?.choose_currency}>
                {/* <Helmet>
                    <script  type="application/ld+json">{ldJson}</script>
                </Helmet> */}

                {international_data?.banner_section?.banner_image && <Banner banner_section = {international_data.banner_section} GQLPage={GQLPage} /> }

                {GQLModules?.length > 0 && GQLModules?.map((Module, index) => 
                    {
                        return(
                            <>
                                {Module.__typename === "GLSTRAPI_ComponentComponentsHomeTilesComponent" && <Info key={Module} Module={Module} GQLPage={GQLPage} /> }

                                {Module.__typename === "GLSTRAPI_ComponentComponentsTrustpilotComponent" && <TrustpilotReviews key={Module} Module={Module} />}

                                {Module.__typename === "GLSTRAPI_ComponentComponentsShowFeaturedProperty" && Module.show && <Features key={Module} Module={Module} menu_slug={GQLPage?.menu?.slug}/> }

                                {Module.__typename === "GLSTRAPI_ComponentComponentsVideoSection" &&  <Customer key={Module} Module={Module} GQLPage={GQLPage}/>}

                                {Module.__typename === "GLSTRAPI_ComponentComponentsLocalExperience" && <LocalExperience key={Module} Module={Module} GQLPage={GQLPage}/>}

                                {Module.__typename === "GLSTRAPI_ComponentComponentsServiceContentCardComponent" && <ServiceContentCard key={Module} Module={Module} GQLPage={GQLPage} /> }

                                {Module.__typename === "GLSTRAPI_ComponentComponentsShowPropertySearch" && Module?.show && <PropertySearch key={Module} Module={Module} />}
                            </>
                        )
                    })
                }

                <International Module={{title: "More Locations", description: international_data?.more_location_content}} id={GQLPage.id} slider_count={slider_count}  />

                <Journey />
                
            </Layout>
        </div>
        
    )
}

export default InternationalDetails

export const query = graphql`
query internationalDetailQuery($id: ID!) {
    glstrapi { 
        internationalLocation(id: $id){
            id
            title
            slug
            seo {
                title
                keywords
                description
                id
                image {
                    alternativeText
                    url
                }
            }
            ggfx_results {
                id
                content_type
                transforms
                src_import_url
                src_cftle
                field
            }
            choose_currency
            more_location_content
            banner_section {
                title
                show_search
                show_reviews
                id
                content
                banner_style
                button_cta_2 {
                    theme
                    target
                    label
                    id
                    custom_link
                    menu_item {
                    slug
                    parent {
                        slug
                        parent {
                        slug
                        parent {
                            slug
                        }
                        }
                    }
                    }
                }
                button_cta_1 {
                    theme
                    target
                    label
                    custom_link
                    id
                    menu_item {
                    slug
                    parent {
                        slug
                        parent {
                        slug
                        parent {
                            slug
                        }
                        }
                    }
                    }
                }
                banner_image {
                    alternativeText
                    url
                }
                mobile_banner_image {
                    alternativeText
                    url
                }
            }


            add_components {
                __typename

                ... on GLSTRAPI_ComponentComponentsVideoSection {
                    id
                    title
                    content
                    read_more_content
                    embed_video_url
                    bg_color
                    cust_padd:padding
                    vid_layout_type:layout_type
                    vid_img_align:image_align
                    button_CTA:Button_CTA {
                    target
                    theme
                    
                    label
                    custom_link
                    menu_item {
                        slug
                        parent {
                        slug
                        parent {
                            slug
                            parent {
                            slug
                            }
                        }
                        }
                    }
                    }
                    image {
                    url
                    alternativeText
                    }
                }

                ... on GLSTRAPI_ComponentComponentsLocalExperience {
                    id
                    title
                    content
                    local_padd:padding
                    background_color: bg_color
                    image_align
                    image {
                        url
                        alternativeText
                    }
                    accordian_module {
                        answer
                        question
                    }
                    button_cta {
                        theme
                        target
                        custom_link
                        id
                        label
                        menu_item {
                            slug
                            parent {
                                slug
                                parent {
                                    slug
                                    parent {
                                        slug
                                    }
                                }
                            }
                        }
                    }
                }

                ... on GLSTRAPI_ComponentComponentsTrustpilotComponent {
                    id
                    title
                    content
                    bg_image {
                        url
                        alternativeText
                    }
                    bgclr:bg_color
                }

                ... on GLSTRAPI_ComponentComponentsServiceContentCardComponent {
                    id
                    title_content
                    service_card {
                        title
                        content
                        cta_link {
                            id
                            label
                            target
                            theme
                            custom_link
                            menu_item {
                                slug
                                parent {
                                    slug
                                    parent {
                                        slug
                                    }
                                }
                            }
                        }
                    }
                }

                ... on GLSTRAPI_ComponentComponentsShowFeaturedProperty {
                    id
                    show
                    title
                    tab_list {
                        add_tab
                        id
                        tab_name
                    }
                    background_clr:bg_color
                    feat_padd:padding
                }


                ... on GLSTRAPI_ComponentComponentsHomeTilesComponent {
                    home_tiles_block {
                        id
                        content
                        title
                        layout_type
                        image {
                            url
                            alternativeText
                        }
                        button_cta {
                            theme
                            target
                            label
                            id
                            custom_link
                            menu_item {
                                slug
                                parent {
                                    slug
                                    parent {
                                        slug
                                    }
                                }
                            }
                        }
                    }
                }

                ... on GLSTRAPI_ComponentComponentsShowPropertySearch {
                    id
                    title
                    show
                }

            }


        }
    }
}`